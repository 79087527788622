import {
  Component,
  DestroyRef,
  Injector,
  OnInit,
  inject,
  runInInjectionContext,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToastComponent } from '@core/components/toast/toast.component';
import { MsalService } from '@azure/msal-angular';
import { environment } from 'environments/environment';
import { Observable, take, timer } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { getSocket } from './chat/services/socket.service';
import { HistoryService } from './chat/services/history.service';
import { ChatService } from './chat/services/chat.service';
import { AccountInfo } from '@azure/msal-browser';
import { UserService } from './chat/services/user.service';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, ToastComponent, RouterModule],
  templateUrl: './app.component.html',
  styleUrl: './app.component.css',
})
export class AppComponent implements OnInit {
  private authService = inject(MsalService);
  private injector = inject(Injector);
  private chatService = inject(ChatService);
  private historyService = inject(HistoryService);
  private destroyRef = inject(DestroyRef);

  protected isLoaded$: Observable<boolean>;
  protected openHistory: boolean = false;

  constructor(private userService: UserService) {
    this.isLoaded$ = this.userService.isLoaded$;
  }

  ngOnInit() {
    this.authService
      .handleRedirectObservable()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(async () => {
        if (!this.authService.instance.getAllAccounts().length) return;
        const account = this.authService.instance.getAllAccounts()[0];
        if (!account) return;
        const expiresOn = (
          await this.authService.instance.acquireTokenSilent({
            account,
            scopes: environment.apiConfig.scopes,
            forceRefresh: false,
          })
        ).expiresOn;
        if (!!expiresOn) this.refreshToken(account, expiresOn);
      });
  }

  refreshToken(account: AccountInfo, expiresOn: Date): void {
    timer(expiresOn.getTime() - Date.now() - 5 * 60 * 1000)
      .pipe(take(1), takeUntilDestroyed(this.destroyRef))
      .subscribe(async () => {
        runInInjectionContext(this.injector, async () => {
          getSocket(true);
          const expiresOn = (
            await this.authService.instance.acquireTokenSilent({
              account,
              scopes: environment.apiConfig.scopes,
              forceRefresh: true,
            })
          ).expiresOn;
          this.chatService.refreshSocket();
          this.historyService.refreshSocket();
          if (!!expiresOn) this.refreshToken(account, expiresOn);
        });
      });
  }
}
