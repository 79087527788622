import {
  DestroyRef,
  Injectable,
  Injector,
  WritableSignal,
  inject,
  runInInjectionContext,
} from '@angular/core';
import { getSocket } from './socket.service';
import { Socket } from 'socket.io-client';
import { ToastService } from './toast.service';
import { Toast } from '../../shared/models/Toast';
import { DocType, Document } from '../../shared/models/Document';
import { environment } from 'environments/environment';
import { StorageService } from 'app/shared/services/storage.service';

type Event = ErrorEvent | CompletionsEvent | EmbeddingEvent | CompletedEvent;

type ErrorEvent = {
  event: 'error';
  error: string;
};

type CompletionsEvent = {
  event: 'completions';
  chunk: string;
};

type EmbeddingEvent = {
  event: 'embedding';
};

type CompletedEvent = {
  event: 'completed';
};

@Injectable({
  providedIn: 'root',
})
export class ChatService {
  private injector = inject(Injector);
  private toastService = inject(ToastService);
  private sessionStorageService: StorageService = inject(StorageService);
  private socket?: Socket;
  private _progressMessageInProgress: {
    [key: string]: WritableSignal<string>;
  } = {};

  constructor() {
    this.refreshSocket();

    inject(DestroyRef).onDestroy(() => {
      this.removeAllListeners();
    });
  }
  public refreshSocket(): void {
    runInInjectionContext(this.injector, () => {
      getSocket().then((socket) => {
        this.socket = socket;
      });
    });
  }

  public getSelectedModel(): string {
    return this.sessionStorageService.getFromSessionStorage('selected-model');
  }

  public setSelectedModel(selectedModel: string) {
    return this.sessionStorageService.storeInSessionStorage(
      'selected-model',
      selectedModel
    );
  }

  public getProgressMessageInProgress(
    key?: string
  ): undefined | WritableSignal<string> {
    if (!key) return;
    return this._progressMessageInProgress[key] ?? undefined;
  }

  public sendPrompt(
    prompt: string,
    conversationId: string,
    selectedVersionGPT: string,
    contextId: string | null
  ): void {
    this.socket?.emit('chat', {
      prompt,
      conversationId,
      modelName: selectedVersionGPT,
      contextId,
      langCode: navigator.language,
    });
  }

  public abortCompletion(conversationId: string): void {
    this.socket?.emit('abort', { conversationId });
  }

  public sendFiles(files: FileList, conversationId: string): void {
    const filesToSend = Array.from(files).map((file: File) => ({
      file,
      type: file.type,
      name: file.name,
    }));

    if (files.length > 0) {
      this.socket?.emit('embedding', {
        conversationId,
        documents: filesToSend,
        model: this.getSelectedModel(),
      });
    }
  }

  public listenEmbedding(
    uploadFileLoading: WritableSignal<boolean>,
    conversationId: string,
    documents: WritableSignal<Document[]>
  ): void {
    uploadFileLoading.set(true);
    this.socket?.on(conversationId, (res: Event) => {
      if (res.event === 'embedding') {
        uploadFileLoading.set(false);
        const toast: Toast = {
          message: 'Fichier téléchargé avec succès.',
          type: 'success',
        };
        this.toastService.showToast(toast);
        this.socket?.off(conversationId);
      }
      if (res.event === 'error') {
        uploadFileLoading.set(false);
        documents.update((actualDocuments) => {
          const updatedDocuments = [...actualDocuments];
          updatedDocuments.pop();
          return updatedDocuments;
        });
        console.error('Error embedding file:', res.error);
        this.toastService.showToast({ type: 'error', message: res.error });
        this.socket?.off(conversationId);
      }
    });
  }

  public listenProgressMessage(
    progressMessage: WritableSignal<string>,
    conversationId: string
  ): void {
    this._progressMessageInProgress[conversationId] = progressMessage;
    this.socket?.on(conversationId, (res: Event) => {
      if (res.event === 'completions')
        this.getProgressMessageInProgress(conversationId)?.update(
          (msg) => msg + res.chunk
        );
      if (res.event === 'error') {
        if (res.error.toLowerCase().includes('abort'))
          this.toastService.showToast({
            type: 'info',
            message: 'Votre recherche a été annulée.',
          });
        else
          this.toastService.showToast({
            type: 'error',
            message: res.error,
          });
      }
      if (res.event === 'completed') {
        this.socket?.off(conversationId);
        delete this._progressMessageInProgress[conversationId];
      }
    });
  }

  public removeAllListeners(): void {
    this.socket?.off();
  }

  public isDocumentTypesValid(files: FileList): boolean {
    const acceptedDocumentTypes: string[] = Object.values(DocType);
    return (
      Array.from(files).filter((file) =>
        acceptedDocumentTypes.includes(file.type)
      ).length === files.length
    );
  }

  public isFileSizeTooBig(files: FileList): boolean {
    let maxSize: number = environment.maxFileSize * 1024 * 1024;
    return Array.from(files).find((x) => x.size > maxSize) != null;
  }
}
