import { inject } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { AuthenticationResult, EventType } from '@azure/msal-browser';
import { environment } from 'environments/environment';
import { Socket, io } from 'socket.io-client';
import { ToastService } from './toast.service';
import { take, timer } from 'rxjs';

let socketPromise: Promise<Socket> | undefined;

export const getSocket = (force: boolean = false) : Promise<Socket> => {
  const authentService = inject(MsalService);
  const toastService = inject(ToastService);
  if (!socketPromise || force) {
    const socketPromiseTmp = socketPromise;
    socketPromise = new Promise<Socket>((resolve) => {
      const eventId = authentService.instance.addEventCallback(evt => {
        if (evt.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) {
          const payload = evt.payload as AuthenticationResult;
          const socket = io(environment.wsConfig.url, {
            autoConnect: true,
            transports: ['websocket'],
            query: {
              token: payload.accessToken,
            },
          });
          if (eventId) authentService.instance.removeEventCallback(eventId);
          resolve(socket);
          socket.on('error', (error: any) => {
            console.error('Socket error', error);
            toastService.showToast({
              message: error || 'Une erreur s\'est produite',
              type: 'error',
              duration: 5000,
            });
          });
          socket.on('exception', () => {
            toastService.showToast({
              message: 'Une erreur est survenue, nous rechargeons le navigateur',
              type: 'error'
            });
            authentService.instance.clearCache();
            timer(1000).pipe(take(1)).subscribe(() => {
              location.reload();
            });
          });
          if (force && socketPromiseTmp) {
            socketPromiseTmp.then((socket) => {
              socket.close();
            });
          }
        }
      });
    });
  }
  return socketPromise;
}